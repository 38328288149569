export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyD6hIBvMJY522AWBCk419b4dQ97qiah_Xg",
        authDomain: "ki-park.cwl.camp"
    },
    region: "europe-west1",
    brain_base_url: "https://ki-park.api.deloitte.camp",
    authParams: ["google"],
    signInType: 'signInWithPopup',
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "KI-Park Catalog",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh.ki-park.cwl.camp",
    store_url: "https://ki-park.cwl.camp",
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    asset_redirect_uri: {},
    api_base_url: "https://ki-park.api.deloitte.camp",
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:mahorst@deloitte.de?subject=Question%20regarding%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    aohQuestions: {
        business_hideDelegationRadio: true,
        business_hideServiceOfferings: true,
        business_hideIsIndustryAligned: true,
        business_hideIndustries: true,
        business_hideAlliances: true,
        business_hideRevenueForm: true,
        business_hideBusinessLineAlignment: true,
        technical_hideContainerizedQuestion: true,
        technical_hideInformOnboardingTeamButton: true
    },
    support: {
        ccEmail: "",
        contact: "mailto:mahorst@deloitte.de"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://ki-park-geniusai.connect.api.deloitte.ai",
            app_url: "https://geniusai.ki-park.cwl.camp/workflow-selector"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
